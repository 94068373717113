import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { NavigationComponent } from './navigation/navigation.component';
import {CarouselModule, ProgressbarModule, TooltipModule, TabsModule} from 'ngx-bootstrap';
import { AboutComponent } from './about/about.component';
import { ValuesComponent } from './values/values.component';
import { SkillsComponent } from './skills/skills.component';
import { ExamplesComponent } from './examples/examples.component';
import { CurrentComponent } from './current/current.component';

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    AboutComponent,
    ValuesComponent,
    SkillsComponent,
    ExamplesComponent,
    CurrentComponent,
  ],
  imports: [
    BrowserModule,
    TooltipModule.forRoot(),
    CarouselModule.forRoot(),
    ProgressbarModule.forRoot(),
    TabsModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
